/* start custom scss snippet */

// Separamos los logos del footer del contenido
.footer__author-links {
    margin-top: 1em;
}

#main-search {
    height: 4rem;
    box-shadow: 0 10px 35px rgba(0,0,0,0.12);
    padding:0 1em 0 4em;
    font-size:1.2em;
}

#main-search-icon {
    font-size: 3rem;
    width:5.3rem;
    line-height: 4.3rem; 
    color:#fb000e;
}

#main-search-box {
    max-width: 40rem;
}

.main-tech {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}

.main-tech-item {
    width: 150px;
    height: 150px;
    padding: 10px;
}

.main-webs-item {
    width: 280px;
    height: auto;
    padding: 10px;
}

.img-fluid {
    max-width: 100%;
}


.ads-aside {
    padding: 0.5em;
}

.qc-cmp2-persistent-link .qc-cmp2-persistent-link:visited {
    color: #fff;
}


// Quitamos el REM de Bootstrap sobre el Nav
.toc--navigator {
    padding-left: 0;
}

// Heads de Manual, Test y Vídeo
.headline {
	border-bottom: 1px dotted #cecece;
    display: block;
    margin: 10px 0;
}

// Lista de elementos identada
ul.milista > li {
    margin-left: 1.5em;
}

.youtube-video {
    aspect-ratio: 16 / 9;
    width: 100%;
}

/* end custom scss snippet */
